import React, { useEffect } from 'react';
import styled from 'styled-components';
import { setBodyColor } from '../../helper/common';

const Div = styled.div``;

type ErrorLayoutProp= {
    children?: React.ReactNode;
}

const ErrorLayout = ({children}: ErrorLayoutProp) => {

    useEffect(()=>{
        setBodyColor('17a2b8');
    });

    return (
        <Div className={'error-page'}>
            <Div className={'noise'}></Div>
            <Div className={'overlay'}></Div>
            <Div className={'terminal'}>{children}</Div>
        </Div>
    )
}

export default ErrorLayout;