import { FileType, MenuButtonType } from "../../api/ResponseType";
import { appUrl } from "../../app/environment";

export const setBodyColor = (color: string) =>{
    document.body.style.backgroundColor=`#${color}`;
}

export const isEmail = (email: string) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email !== '' && regex.test(email)) {
        return true;
    }
    return false;
}

export const fullDateFormat = (rawDate: Date | null) => {
    if(rawDate){
        const date = new Date(rawDate);
        const days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
        const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        const dateOrdinal=(dom: number)=> {
            if (dom === 31 || dom === 21 || dom === 1) return dom + "st";
            else if (dom === 22 || dom === 2) return dom + "nd";
            else if (dom === 23 || dom === 3) return dom + "rd";
            else return dom + "th";
        }
        return days[date.getDay()]+' '+dateOrdinal(date.getDate())+', '+ months[date.getMonth()]+', '+date.getFullYear();
    }
    return "--"
}

export const forceDownload = (blob: Blob, filename: string) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    const name = filename.replaceAll(' ', '-').toLowerCase();
    link.href = url;
    link.setAttribute('download',`${name}.pdf`);
    document.body.appendChild(link);
    link.click();
    if (link.parentNode) link.parentNode.removeChild(link);
}

export const getBase64 = (blob:Blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("load", function () {
            resolve(reader.result);
        }, false);
        reader.onerror = () => {
            return reject(Promise);
        };
        return reader.readAsDataURL(blob);
    });
}

export const getFileUrl = (document: FileType) => {
   return appUrl+document.path+document.name;
}

export const hasSubMenu = (menus: MenuButtonType[], id: number) => {
    const menu = menus.filter((menu: MenuButtonType)=> menu.menu_button_id === id);
    return (menu.length)?true: false;
}

export const getSubMenu = (menus: MenuButtonType[], id: number) => {
    return menus.filter((menu: MenuButtonType)=> menu.menu_button_id === id);
}

export const getSubMenuLink = (menu: MenuButtonType) => {
    if(menu.type === null){
        return '#';
    }
    if(menu.type === 'static'){
        return `/${menu.link}` || '#';
    }
    if(menu.type === 'dynamic'){
        return `/page/${menu.link}` || '#';
    }
}