import React from 'react';
import Error404 from '../../components/error/404';
import ErrorLayout from '../../components/layouts/ErrorLayout';



export const NotFound = (props: any)=>{

    return (
        <ErrorLayout><Error404/></ErrorLayout>
    );
}