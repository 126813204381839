import React from 'react';
import ErrorLayout from '../../components/layouts/ErrorLayout';
import UnderMaintenance from '../../components/error/UnderMaintenance';



export const UnderMaintenancePage = (props: any)=>{

    return (
        <ErrorLayout><UnderMaintenance /></ErrorLayout>
    );
}