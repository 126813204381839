class ErrorWrapper{
    ErrorWrapper: any;
    
    public async parseError(response:any){
        var fun: string = `_${response.status}`;
        return (this as any)[fun](response);
    }

    private async _200(responseRaw: any){
        const raw = await responseRaw.json();
        return {type:'response', status: raw.status, response: raw.response, message: raw.message, code: 200}
    }

    private async _401(responseRaw:any){
        const raw = await responseRaw.json();
        return {type:'error', status: false, response: raw.errors, message: raw.message, code: 422}
    }

    private async _422(responseRaw:any){
        const raw = await responseRaw.json();
        return {type:'error', status: false, response: raw.errors, message: raw.message, code: 422}
        
    }
}

export default new ErrorWrapper();