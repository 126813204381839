import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import CommonReducer from '../store/CommonSlice';
import AuthReducer from '../store/AuthSlice';
import AcademicReducer from '../store/AcademicSlice';
import ContentReducer from '../store/ContentSlice';
import CurricularReducer from '../store/CurricularSlice';

export const store = configureStore({
  reducer: {
    common: CommonReducer,
    authenticated: AuthReducer,
    academic: AcademicReducer,
    content: ContentReducer,
    curricular: CurricularReducer
  },
});
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,RootState,unknown,Action<string>>;
