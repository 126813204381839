import React from 'react'
import { Div, H1, Span, Paragraph } from './style'


const UnderMaintenance = () => {
    
    return (
        <Div>
            <H1>Under <Span className={'errorcode'}>Maintenance</Span></H1>
            <Paragraph className={'output'}>At the moment site is under annual maintenance, sorry for the inconvenience.</Paragraph>
        </Div>
    )
}

export default UnderMaintenance;