import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { getConcessionCodes, getLanguages, getMenuButtons } from "../api";
import { ConcessionCodeType, LanguageType, MenuButtonType } from "../api/ResponseType";
import text from '../app/translation.json';

export const CommonSlice = createSlice({
    name: 'common',
    initialState: {
        loading:true as boolean,
        languages:[] as LanguageType[],
        locale:'en' as string,
        translation: text.en as object,
        message:'' as string,
        version: process.env.REACT_APP_VERSION as string,
        menus: [] as MenuButtonType[],
        concessionCodes: [] as ConcessionCodeType[]
    },
    reducers:{
        setLoading: (state, action:PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setLanguages: (state, action:PayloadAction<LanguageType[]>) => {
            state.languages = action.payload;
        },
        setLocale: (state, action:PayloadAction<string>) => {
            localStorage.setItem("locale", action.payload);
            state.locale = action.payload;
        },
        setTranslation: (state, action:PayloadAction<object>) => {
            state.translation = action.payload;
        },
        setAlert: (state, action:PayloadAction<string>) =>{
            state.message = action.payload;
        },
        refreshLocale: state =>{
            const locale = localStorage.getItem("locale");
            state.locale = locale || 'en';
        },
        setMenus: (state, action:PayloadAction<MenuButtonType[]>) => {
            state.menus = action.payload;
        },
        setConcessionCodes: (state, action:PayloadAction<ConcessionCodeType[]>) => {
            state.concessionCodes = action.payload;
        }
    }
});

export const {
    setLoading,
    setLanguages,
    setLocale,
    setTranslation,
    setAlert,
    refreshLocale,
    setMenus,
    setConcessionCodes
} = CommonSlice.actions;

export const loadLanguages = () => async (dispatch: any) => {
    dispatch(setLoading(true));
    const languagesResponse = await getLanguages();
    if(languagesResponse.status){
        const languages = languagesResponse.response;
        dispatch(setLanguages(languages));
        dispatch(setLoading(false));
    }
}

export const loadTranslation = (locale:string) => async (dispatch: any) => {
    if(locale === 'en'){
        const texts = text.en;
        dispatch(setTranslation(texts));
    }
    if(locale === 'hi'){
        const texts = text.hi;
        dispatch(setTranslation(texts));
    }
}

export const loadMenuButtons = () => async (dispatch: any) => {
    dispatch(setLoading(true));
    const menusResponse = await getMenuButtons();
    if(menusResponse.status){
        const menus = menusResponse.response;
        dispatch(setMenus(menus));
        dispatch(setLoading(false));
    }
}

export const loadConcessionCodes = () => async (dispatch: any) => {
    const concessionCodesResponse = await getConcessionCodes();
    if (concessionCodesResponse.status) {
        const concessionCodes = concessionCodesResponse.response;
        dispatch(setConcessionCodes(concessionCodes));
    }
}

export const selectLoading = (state:any) => state.common.loading;
export const selectLanguages = (state:any) => state.common.languages;
export const selectLocale = (state:any) => state.common.locale;
export const selectTranslation = (state:any) => state.common.translation;
export const selectAlert = (state:any) => state.common.message;
export const selectVersion = (state:any) => state.common.version;
export const selectMenuItems = (state: any) =>  state.common.menus.filter((menu: MenuButtonType)=> menu.menu_button_id === null);
export const selectSubMenuItems = (state: any) =>  state.common.menus.filter((menu: MenuButtonType)=> menu.menu_button_id !== null);
export const selectConcessionCodes = (state: any) => state.common.concessionCodes;

export default CommonSlice.reducer;