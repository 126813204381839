import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { getCommittee, getCommittees } from "../api";
import { CommitteeType } from "../api/ResponseType";

export const CurricularSlice = createSlice({
    name: 'curricular',
    initialState: {
        loading:true as boolean,
        committees: [] as CommitteeType[],
        committee: {} as CommitteeType
    },
    reducers:{
        setLoading: (state, action:PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setCommittees: (state, action:PayloadAction<CommitteeType[]>) => {
            state.committees = action.payload;
        },
        setCommittee: (state, action:PayloadAction<CommitteeType>) => {
            state.committee = action.payload;
        },
    }
});

export const {
    setLoading,
    setCommittees,
    setCommittee
} = CurricularSlice.actions;

export const loadCommittees = () => async (dispatch: any) => {
    dispatch(setLoading(true));
    const committeeResponse = await getCommittees();
    if(committeeResponse.status){
        const committees = committeeResponse.response;
        dispatch(setCommittees(committees));
        dispatch(setLoading(false));
    }
}

export const loadCommittee = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const committeeResponse = await getCommittee(id);
    if(committeeResponse.status){
        const committee = committeeResponse.response;
        dispatch(setCommittee(committee));
        dispatch(setLoading(false));
    }
}

export const selectLoading = (state:any) => state.curricular.loading;
export const selectCommittees = (state:any) => state.curricular.committees;
export const selectCommittee = (state:any) => state.curricular.committee;

export default CurricularSlice.reducer;