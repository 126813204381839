import { navigate } from "@reach/router";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { profile, signOut } from "../api";
import { AuthUserType } from '../api/ResponseType';
export const AuthSlice = createSlice({
    name: 'authenticated',
    initialState: {
        loading:false as boolean,
        token:'' as string,
        user:{} as AuthUserType,
        isLoggedIn: false as boolean,
   },
    reducers:{
        setLoading: (state, action:PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setToken: (state, action:PayloadAction<string>) => {
            localStorage.setItem("token", action.payload);
            state.token = action.payload;
            state.isLoggedIn = true;
        }, 
        setUser: (state, action:PayloadAction<AuthUserType>) => {
            state.user = action.payload;
        }, 
        setLoggedIn: (state, action:PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        },
        refresh:state => {
            const token = localStorage.getItem("token");
            state.token = token || '';
            state.isLoggedIn = token? true:false;
        }
   }
});

export const {
    setLoading,
    setToken,
    setUser,
    setLoggedIn,
    refresh
} = AuthSlice.actions;

export const loadUser = () => async (dispatch: any) => {
    const userResponse = await profile();
    if(userResponse.status){
        const user = userResponse.response || {};
        dispatch(setUser(user));
        dispatch(refresh());
        dispatch(setLoading(false));
    }
}

export const logUser = (user: AuthUserType) => (dispatch: any) => {
    dispatch(setUser(user));
    dispatch(setToken(user.token));
    dispatch(setLoading(false));
}

export const logOut = () => async (dispatch: any) => {
    await signOut();
    localStorage.clear();
    dispatch(refresh());
    navigate('sign-in')
}

export const selectLoading = (state:any) => state.authenticated.loading;
export const selectToken = (state: any) => state.authenticated.token;
export const selectIsLoggedIn = (state: any) => state.authenticated.isLoggedIn;
export const selectUser = (state:any) => state.authenticated.user;

export default AuthSlice.reducer;