import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { getDepartments, getCourses, getDepartment, getCourse, getSubjects, getTopicByYear, getVideoLecture } from "../api";
import { CourseType, DepartmentType, SubjectType, TeacherType, TopicType, VideoLectureType } from "../api/ResponseType";

export const AcademicSlice = createSlice({
    name: 'academic',
    initialState: {
        loading:true as boolean,
        departments:[] as DepartmentType[],
        department: {} as DepartmentType,
        courses:[] as CourseType[],
        course: {} as CourseType,
        teachers:[] as TeacherType[],
        subjects:[] as SubjectType[],
        topics:[] as TopicType[],
        videos: [] as VideoLectureType[]
    },
    reducers:{
        setLoading: (state, action:PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setDepartments: (state, action:PayloadAction<DepartmentType[]>) => {
            state.departments = action.payload;
        },
        setDepartment: (state, action:PayloadAction<DepartmentType>) => {
            state.department = action.payload;
        },
        setCourses: (state, action:PayloadAction<CourseType[]>) => {
            state.courses = action.payload;
        },
        setCourse: (state, action:PayloadAction<CourseType>) => {
            state.course = action.payload;
        },
        setTeachers: (state, action:PayloadAction<TeacherType[]>) => {
            state.teachers = action.payload;
        },
        setSubjects: (state, action:PayloadAction<SubjectType[]>) => {
            state.subjects = action.payload;
        },
        setTopics: (state, action:PayloadAction<TopicType[]>) => {
            state.topics = action.payload;
        },
        setVideos: (state, action:PayloadAction<VideoLectureType[]>) => {
            state.videos = action.payload;
        }
   }
});

export const {
    setLoading,
    setDepartments,
    setDepartment,
    setCourses,
    setTeachers,
    setSubjects,
    setCourse,
    setTopics,
    setVideos
} = AcademicSlice.actions;

export const loadDepartments = () => async (dispatch: any) => {
    dispatch(setLoading(true));
    const departmentsResponse = await getDepartments();
    if(departmentsResponse.status){
        const departments = departmentsResponse.response;
        dispatch(setDepartments(departments));
        dispatch(setLoading(false));
    }
}

export const loadDepartment = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const departmentResponse = await getDepartment(id);
    if(departmentResponse.status){
        const department = departmentResponse.response;
        dispatch(setTeachers(department.teachers));
        dispatch(setDepartment(department));
        dispatch(setSubjects(department.subjects));
        dispatch(setLoading(false));
    }
}

export const loadCourses = () => async (dispatch: any) => {
    dispatch(setLoading(true));
    const coursesResponse = await getCourses();
    if(coursesResponse.status){
        const courses = coursesResponse.response;
        dispatch(setCourses(courses));
        dispatch(setLoading(false));
    }
}

export const loadCourse = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const courseResponse = await getCourse(id);
    if(courseResponse.status){
        const course = courseResponse.response;
        dispatch(setCourse(course));
        dispatch(setSubjects(course.subjects));
        dispatch(setLoading(false));
    }
}

export const loadCourseSubject = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const courseResponse = await getCourse(id);
    if(courseResponse.status){
        const course = courseResponse.response;
        dispatch(setCourse(course));
        dispatch(setLoading(false));
    }
}

export const loadSubjects = () => async (dispatch: any) => {
    dispatch(setLoading(true));
    const subjectResponse = await getSubjects();
    if(subjectResponse.status){
        const subjects = subjectResponse.response;
        dispatch(setSubjects(subjects));
        dispatch(setLoading(false));
    }
}

export const loadTopics = (subject:number, year: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const topicResponse = await getTopicByYear(subject, year);
    if(topicResponse.status){
        const topics = topicResponse.response;
        dispatch(setTopics(topics));
        dispatch(setLoading(false));
    }
}

export const loadVideoLecture = (subject: number, topic:number, year: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const videoResponse = await getVideoLecture(subject,topic, year);
    if(videoResponse.status){
        const videos = videoResponse.response;
        dispatch(setVideos(videos));
        dispatch(setLoading(false));
    }
}

export const selectLoading = (state:any) => state.academic.loading;
export const selectDepartments = (state:any) => state.academic.departments;
export const selectDepartment = (state:any) => state.academic.department;
export const selectCourses = (state:any) => state.academic.courses;
export const selectCourse = (state:any) => state.academic.course;
export const selectTeachers = (state:any) => state.academic.teachers;
export const selectSubjects = (state:any) => state.academic.subjects;
export const selectTopics = (state:any) => state.academic.topics;
export const selectVideos = (state:any) => state.academic.videos;

export default AcademicSlice.reducer;