import React from 'react'
import { useSelector } from 'react-redux';
import { selectTranslation } from '../../store/CommonSlice';
import { Div, H1, Span, Paragraph, Anchor } from './style'


const Error404 = () => {
    
    const translation = useSelector(selectTranslation);
    const text = translation.errorPages.error404;

    return (
        <Div>
            <H1>{text.error} <Span className={'errorcode'}>{text.code}</Span></H1>
            <Paragraph className={'output'}>{text.lineOne}</Paragraph>
            <Paragraph className={'output'}>{text.lineTwo} <Anchor href={'/'}>{text.lineThree}</Anchor> {text.lineFour}</Paragraph>
            <Paragraph className={'output'}>{text.goodLuck}</Paragraph>
        </Div>
    )
}

export default Error404;