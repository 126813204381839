import {apiUrl} from '../app/environment';
import ErrorWrapper from './ErrorWrapper';



const createUrl = (urlPath: string) => `${apiUrl}${urlPath}`

const getHttpHeaders = () => {
    const token = localStorage.getItem("token");
    const locale = localStorage.getItem("locale") || 'en';
    const headers: any = {'Content-Type': 'application/json','Accept': 'application/json'}
    headers['X-localization'] = locale;
    if (token) headers['Authorization'] = `Bearer ${token}`;
    return headers;
}

const getHttpFormHeaders = () => {
    const token = localStorage.getItem("token");
    const locale = localStorage.getItem("locale") || 'en';
    const headers: any = {'Accept': 'application/json'}
    headers['X-localization'] = locale;
    if (token) headers['Authorization'] = `Bearer ${token}`;
    return headers;
}

class ApiWrapper{

    public async wrapPromise (promise: Promise<Response>) {
        return this.wrapResponse(await promise);
    }

    public async wrapResponse(rawResponse: Response) {
        return ErrorWrapper.parseError(rawResponse);
    }

    public async post(urlPath: string, body:any){
        const url = createUrl(urlPath)
        const rawResponse = await fetch(url, {
            'method': 'POST',
            'headers': getHttpHeaders(),
            'body': JSON.stringify(body)
        });
        return this.wrapResponse(rawResponse);
    }

    public async patch(urlPath: string, body:any){
        const url = createUrl(urlPath)
        const rawResponse = await fetch(url, {
            'method': 'PATCH',
            'headers': getHttpHeaders(),
            'body': JSON.stringify(body)
        });
        return this.wrapResponse(rawResponse);
    }

    public async get(urlPath: string){
        const url = createUrl(urlPath)
        const rawResponse =  await fetch(url, {
            'method': 'GET',
            'headers': getHttpHeaders(),
        });
        return this.wrapResponse(rawResponse);
    }

    public async delete(urlPath: string){
        const url = createUrl(urlPath)
        const rawResponse =  await fetch(url, {
            'method': 'DELETE',
            'headers': getHttpHeaders(),
        });
        return this.wrapResponse(rawResponse);
    }

    public async blob(urlPath: string){
        const url = createUrl(urlPath)
        const rawResponse =  await fetch(url, {
            'method': 'GET',
            'headers': getHttpHeaders(),
        });
        return rawResponse.blob();
    }

    public async formPost(urlPath: string, body:any){
        const url = createUrl(urlPath)
        const rawResponse = await fetch(url, {
            'method': 'POST',
            'headers': getHttpFormHeaders(),
            'body': body
        });
        return this.wrapResponse(rawResponse);
    }

}

export default new ApiWrapper();