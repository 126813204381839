import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { getNotices, getNotice, getPage, getPages, getSlider, getSliders, getArchive, getArchives, getStrips, getSPages } from "../api";
import { ArchiveDocumentType, NoticeType, PageType, SliderType, StandardDropdownType, StripType } from "../api/ResponseType";
import { getFileUrl } from "../helper/common";
import staticDb from "./db.json";

export const ContentSlice = createSlice({
    name: 'content',
    initialState: {
        loading:true as boolean,
        pages:[] as PageType[],
        page:{} as PageType,
        notices:[] as NoticeType[],
        notice:{} as NoticeType,
        sliders:[] as SliderType[],
        slider:{} as SliderType,
        archives:[] as ArchiveDocumentType[], 
        archive:{} as ArchiveDocumentType,
        document: '' as string,
        strips: [] as StripType[],
        about: {} as PageType,
        important: {} as PageType,
        ecorner: {} as PageType,
        standards: staticDb.classes as StandardDropdownType[]
    },
    reducers:{
        setLoading: (state, action:PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setPages: (state, action:PayloadAction<PageType[]>) => {
            state.pages = action.payload;
        },
        setNotices: (state, action:PayloadAction<NoticeType[]>) => {
            state.notices = action.payload;
        },
        setNotice: (state, action:PayloadAction<NoticeType>) => {
            state.notice = action.payload;
        },
        setSliders: (state, action:PayloadAction<SliderType[]>) => {
            state.sliders = action.payload;
        },
        setSlider:(state, action:PayloadAction<SliderType>) => {
            state.slider = action.payload;
        },
        setPage: (state, action:PayloadAction<PageType>) => {
            state.page = action.payload;
        },
        setArchives: (state, action:PayloadAction<ArchiveDocumentType[]>) => {
            state.archives = action.payload;
        },
        setArchive: (state, action:PayloadAction<ArchiveDocumentType>) => {
            state.archive = action.payload;
        },
        setDocument: (state, action) => {
            state.document = action.payload;
        },
        setStrips: (state, action: PayloadAction<StripType[]>) => {
            state.strips = action.payload
        },
        setAbout: (state, action:PayloadAction<PageType>) => {
            state.about = action.payload;
        },
        setImportant: (state, action:PayloadAction<PageType>) => {
            state.important = action.payload;
        },
        setECorner: (state, action:PayloadAction<PageType>) => {
            state.ecorner = action.payload;
        },
    }
});

export const {
    setLoading,
    setPages,
    setPage,
    setSliders,
    setSlider,
    setNotices,
    setNotice,
    setArchives,
    setArchive,
    setDocument,
    setStrips,
    setAbout,
    setImportant,
    setECorner
} = ContentSlice.actions;

export const loadPages = () => async (dispatch: any) => {
    dispatch(setLoading(true));
    const pagesResponse = await getPages();
    if(pagesResponse.status){
        const pages = pagesResponse.response;
        dispatch(setPages(pages));
        dispatch(setLoading(false));
    }
}

export const loadPage = (page: string) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const pageResponse = await getPage(page);
    if(pageResponse.status){
        const page = pageResponse.response;
        dispatch(setPage(page));
        dispatch(setLoading(false));
    }
}

export const loadIndexData = (about: string, important: string, ecorner: string) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const pageResponse = await getSPages();
    if(pageResponse.status){
        const pages = pageResponse.response;
        const aboutData: PageType = pages.find((page: PageType) => page.slug === about)
        const importantData: PageType = pages.find((page: PageType) => page.slug === important)
        const ecornerData: PageType = pages.find((page: PageType) => page.slug === ecorner)
        dispatch(setAbout(aboutData))
        dispatch(setImportant(importantData))
        dispatch(setECorner(ecornerData))
        dispatch(setLoading(false))
    }
}

export const loadSliders = (sliderType:string) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const slidersResponse = await getSliders(sliderType);
    if(slidersResponse.status){
        const sliders = slidersResponse.response;
        dispatch(setSliders(sliders));
        dispatch(setLoading(false));
    }
}

export const loadSlider = (sliderType:string) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const sliderResponse = await getSlider(sliderType);
    if(sliderResponse.status){
        const slider = sliderResponse.response;
        dispatch(setSlider(slider));
        dispatch(setLoading(false));
    }
}

export const loadNotices = () => async (dispatch: any) => {
    dispatch(setLoading(true));
    const noticesResponse = await getNotices();
    if(noticesResponse.status){
        const notices = noticesResponse.response;
        dispatch(setNotices(notices));
        dispatch(setLoading(false));
    }
}

export const loadNotice = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const noticeResponse = await getNotice(id);
    if(noticeResponse.status){
        const notice = noticeResponse.response;
        dispatch(setNotice(notice));
        dispatch(setLoading(false));
    }
}

export const loadArchives = () => async (dispatch: any) => {
    dispatch(setLoading(true));
    const archivesResponse = await getArchives();
    if(archivesResponse.status){
        const archives = archivesResponse.response;
        dispatch(setArchives(archives));
        dispatch(setLoading(false));
    }
}

export const loadArchive = (id: number) => async (dispatch: any) => {
    dispatch(setLoading(true));
    const archiveResponse = await getArchive(id);
    if(archiveResponse.status){
        const archive = archiveResponse.response;
        dispatch(setDocument(getFileUrl(archive.document)));
        dispatch(setArchive(archive));
        dispatch(setLoading(false));
    }
}

export const loadStrips = () => async (dispatch: any) => {
    dispatch(setLoading(true));
    const stripResponse = await getStrips();
    if(stripResponse.status){
        const strips = stripResponse.response;
        dispatch(setStrips(strips));
        dispatch(setLoading(false));
    }
}

export const selectLoading = (state:any) => state.content.loading;
export const selectPages = (state:any) => state.content.pages;
export const selectPage = (state:any) => state.content.page;
export const selectNotices = (state:any) => state.content.notices;
export const selectNotice = (state: any) => state.content.notice;
export const selectSliders = (state:any) => state.content.sliders;
export const selectSlider = (state:any) => state.content.slider;
export const selectArchives = (state:any) => state.content.archives;
export const selectArchive = (state:any) => state.content.archive;
export const selectDocument = (state:any) => state.content.document;
export const selectStrips = (state: any) => state.content.strips;
export const selectAbout = (state: any) => state.content.about;
export const selectImportant = (state: any) => state.content.important;
export const selectECorner = (state: any) => state.content.ecorner;
export const selectStandards = (state: any) => state.content.standards;

export default ContentSlice.reducer;