import React, { useEffect } from 'react';
import { Router } from '@reach/router';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { NotFound } from './pages/error/404';
import { useDispatch, useSelector } from 'react-redux';
import { loadTranslation, refreshLocale, selectLocale } from './store/CommonSlice';
// import { HomeScreen } from './pages';
// import { LoginScreen } from './pages/auth/login';
// import { AccountScreen } from './pages/account/account';
// import { RegisterScreen } from './pages/auth/register';
// import { AboutScreen } from './pages/static/about';
// import { ContactScreen } from './pages/static/contact';
// import { PrivacyScreen } from './pages/static/privacy';
// import { TermScreen } from './pages/static/terms';
// import { AdmissionProcessScreen } from './pages/static/admission';
// import { CareerScreen } from './pages/static/career';
// import { DepartmentListScreen } from './pages/academic/departments';
// import { CourseListScreen } from './pages/academic/courses';
// import { VerifyScreen } from './pages/auth/verify';
// import { DepartmentScreen } from './pages/academic/department';
// import { CourseScreen } from './pages/academic/course';
// import { DynamicPageScreen } from './pages/dynamic/page';
// import { NoticeListScreen } from './pages/notice/notices';
// import { NoticeScreen } from './pages/notice/notice';
// import { ForgotPasswordScreen } from './pages/auth/forgot';
// import { ArchiveScreen } from './pages/archive/archive';
// import { CommitteeScreen } from './pages/curricular/committee';
// import { CommitteeListScreen } from './pages/curricular/committees';
// import { VideoLectureScreen } from './pages/academic/videoLecture';
// import { RegistrationFormPage } from './pages/registration/registration';
// import { TeachingStaffListScreen } from './pages/faculty/teaching-staff';
import { UnderMaintenancePage } from './pages/error/UnderMaintenance';

const App = () => {
  const dispatch = useDispatch();
  const locale = useSelector(selectLocale);
  useEffect(()=>{
    dispatch(refreshLocale())
    dispatch(loadTranslation(locale));
  },[dispatch,locale]);
  return (
    <Router>
      <UnderMaintenancePage path='/' />
      {/* <HomeScreen path='/' />
      <AboutScreen path='/about'/>
      <ContactScreen path='/contact'/>
      <PrivacyScreen path='/privacy-policies'/>
      <TermScreen path='/terms-and-conditions'/>
      <AdmissionProcessScreen path='/admission-process'/>
      <CareerScreen path='/career'/>
      <DepartmentListScreen path='/departments'/>
      <DepartmentScreen path='/department/:departmentId/:departmentName'/>
      <CourseListScreen path='/courses'/>
      <CourseScreen path='/course/:courseId/:courseName'/>
      <VideoLectureScreen path='/video-lecture'/>
      <DynamicPageScreen path='/page/:page'/>
      <NoticeListScreen path='/notices'/>
      <NoticeScreen path='/notice/:noticeId'/>
      <CommitteeListScreen path='/committees'/>
      <CommitteeScreen path='/committee/:committeeId/:committeeName'/>
      <ArchiveScreen path='/archive'/>
      <RegistrationFormPage path='/registration-form'/>
      <LoginScreen path='/sign-in'/>
      <RegisterScreen path='/sign-up'/>
      <ForgotPasswordScreen path='/forgot-password'/>
      <VerifyScreen path='/verify-account'/>
      <AccountScreen path='/my-account'/>
      <TeachingStaffListScreen path='/teaching-staff' /> */}
      <NotFound default/>
    </Router>
  );
}
export default App;
